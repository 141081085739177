import { useDeviceType } from '@frontastic/catwalk/js/helper/hooks/useDeviceType';
import React, { Fragment } from 'react';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import * as screenSizes from 'helpers/utils/screensizes';
import { TasticWrapper } from 'frontastic/lib/component';
import {
  Cell as LayoutElement,
  CellConfiguration,
  TasticRegistry,
  PageDataResponse,
  PagePreviewDataResponse,
  DataSources,
  PageFolder,
} from 'frontastic/lib/types';

const isPreviewDataResponse = (
  pageDataResponse: PageDataResponse | PagePreviewDataResponse,
): pageDataResponse is PagePreviewDataResponse => {
  return (pageDataResponse as PagePreviewDataResponse).previewId !== undefined;
};

const getPreviewId = (pageDataResponse: PageDataResponse | PagePreviewDataResponse) => {
  if (isPreviewDataResponse(pageDataResponse)) {
    return pageDataResponse.previewId;
  }

  return undefined;
};

const LegacyFrontasticRenderer = ({
  data,
  tastics = {},
  wrapperClassName,
  currentHighlight,
}: {
  data: PageDataResponse | PagePreviewDataResponse;
  tastics: TasticRegistry;
  wrapperClassName?: string;
  currentHighlight?: string;
}) => {
  const [isBiggerThanMobile] = useMediaQuery(screenSizes.mobile);

  function deviceVisibility(conf: CellConfiguration) {
    return `${conf.mobile ? 'block' : 'hidden'} ${conf.tablet ? 'md:block' : 'md:hidden'} ${
      conf.desktop ? 'lg:block' : 'lg:hidden'
    }`;
  }

  if (data?.page?.sections?.kit) {
    return (
      <main className="c-page-body">
        <div className="c-page-wrapper o-wrapper">
          <Region
            tastics={tastics}
            layoutElements={data?.page?.sections?.kit?.layoutElements}
            pageFolder={data.pageFolder}
            dataSources={data.data.dataSources}
            previewId={getPreviewId(data)}
            currentHighlight={currentHighlight}
          />
        </div>
      </main>
    );
  }

  return (
    <Fragment>
      <header className="c-page-head o-wrapper">
        <Region
          tastics={tastics}
          layoutElements={data?.page?.sections?.head?.layoutElements}
          pageFolder={data.pageFolder}
          dataSources={data.data.dataSources}
          previewId={getPreviewId(data)}
          currentHighlight={currentHighlight}
        />
      </header>
      <main className="c-page-body">
        <div className="c-page-wrapper o-wrapper">
          <Region
            tastics={tastics}
            layoutElements={data?.page?.sections?.main?.layoutElements}
            pageFolder={data.pageFolder}
            dataSources={data.data.dataSources}
            previewId={getPreviewId(data)}
            currentHighlight={currentHighlight}
          />
        </div>
      </main>
      <footer className="c-page-foot o-wrapper">
        <Region
          tastics={tastics}
          layoutElements={data?.page?.sections?.footer?.layoutElements}
          pageFolder={data.pageFolder}
          dataSources={data.data.dataSources}
          previewId={getPreviewId(data)}
          currentHighlight={currentHighlight}
        />
      </footer>
    </Fragment>
  );
};

const createRenderTree = (layoutElements: LayoutElement[], currentDeviceType: string) => {
  const renderTree = [[]];
  let row = 0;
  let colsPerRow = 0;

  for (let i = 0; i < layoutElements.length; i++) {
    if (!renderTree[row]) {
      renderTree[row] = [];
    }

    if (layoutElements[i].configuration[currentDeviceType]) {
      renderTree[row].push(layoutElements[i]);
    }

    colsPerRow += layoutElements[i].configuration.size || 12;

    if (colsPerRow >= 12) {
      colsPerRow = 0;
      row++;
    }
  }

  return renderTree;
};

const Row = ({ children }: React.PropsWithChildren<object>) => {
  return (
    <Cell size={12}>
      <Grid>{children}</Grid>
    </Cell>
  );
};

const Grid = ({ children }: React.PropsWithChildren<object>) => {
  return <div className={`o-grid`}>{children}</div>;
};

const Cell = ({
  size = 12,
  prefix = 'o-cell',
  children,
}: React.PropsWithChildren<{ size?: 1 | 2 | 3 | 4 | 6 | 8 | 12; prefix?: string }>) => {
  return <div className={`${prefix} ${prefix}--${size}`}>{children}</div>;
};

const Region = ({
  tastics = {},
  currentHighlight,
  layoutElements,
  pageFolder,
  dataSources,
  previewId,
}: {
  tastics: TasticRegistry;
  currentHighlight?: string;
  layoutElements: LayoutElement[];
  pageFolder: PageFolder;
  dataSources: DataSources;
  previewId?: string;
}) => {
  const deviceType = useDeviceType();

  const renderTree = createRenderTree(layoutElements, deviceType);

  return (
    <div className={`o-grid o-region`}>
      {renderTree
        .filter((group) => group.length > 0)
        .map((group, i) => {
          return (
            <Row key={`row_${i}`}>
              {group.map((cell) => {
                return (
                  <Cell key={cell.layoutElementId} size={cell.configuration.size || 12}>
                    {cell.tastics.map((t) => (
                      <TasticWrapper
                        tastics={tastics}
                        key={t.tasticId}
                        data={t}
                        dataSources={dataSources}
                        pageFolder={pageFolder}
                        highlight={currentHighlight === t.tasticId}
                        previewId={previewId}
                      />
                    ))}
                  </Cell>
                );
              })}
            </Row>
          );
        })}
    </div>
  );
};

export default LegacyFrontasticRenderer;
