import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Chevron } from '../../../../../../../frontend/legacy/icons/tailwind-icons/icon-cheveron-down.svg';
import Markdown from '@frontastic/catwalk/src/js/component/markdown';

function TextPanel({ title, body }) {
  const [open, setOpen] = useState(false);
  return (
    body && (
      <div className="border-t border-gray-300 pb-4 pt-5">
        <h3 className="mb-2 flex cursor-pointer justify-between font-bold text-gray-900" onClick={() => setOpen(!open)}>
          <span>{title || ''}</span>
          <i className="text-xl">
            <Chevron
              className={`transition-transform duration-500 ease-in-out ${open ? 'rotate-180 transform' : ''}`}
            />
          </i>
        </h3>
        <Markdown
          className={`markdown-text overflow-hidden text-sm text-gray-700 transition-max-height duration-300 ease-in ${
            open ? 'max-h-736px' : 'hidden'
          }`}
          text={body}
        />
      </div>
    )
  );
}

TextPanel.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
};

export default TextPanel;
