import React, { Component } from 'react';
import { connect } from 'react-redux';
import { markdown } from 'markdown';

import ComponentInjector from '../app/injector';

import app from '../app/app';

interface Props {
  className?: string;
  text?: string;
}

class Markdown extends Component<Props> {
  static defaultProps = {
    className: 'c-markdown s-text',
  };

  componentDidMount() {
    // @ts-ignore
    let links = this.refs.markdown.querySelectorAll('a');
    for (let link of links) {
      const ref = link.getAttribute('href');
      if (ref.indexOf('://') === -1) {
        // only prevent default if it's not an external link
        link.onclick = (event) => {
          event.preventDefault();

          // @TODO: We might need handling for fragment links, too
          app.getRouter().history.push(link.getAttribute('href'));
        };
      }
    }
  }

  render() {
    return (
      <div
        className={this.props.className}
        ref="markdown"
        dangerouslySetInnerHTML={{
          __html: markdown.toHTML(this.props.text || ''),
        }}
      />
    );
  }
}

export default connect((globalState, props) => {
  return {
    // @ts-ignore
    context: globalState.app.context,
    ...props,
  };
})(ComponentInjector.return('Markdown', Markdown));
