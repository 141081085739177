import React from 'react';
import Reference from '@frontastic/catwalk/src/js/component/reference';

import { CtaColor } from '../';

interface Props {
  label: string;
  reference: any;
  color: CtaColor;
  isButton: boolean;
}

export default function Cta({ label, reference, color, isButton = false }: Props) {
  return (
    <>
      {label && reference && (
        <Reference
          reference={reference}
          className={`text-white ${
            isButton ? 'bg' : 'text'
          }-${color}-600 rounded mt-6 py-3 px-4 text-base font-semibold`}
        >
          {label}
        </Reference>
      )}
    </>
  );
}
