import { HTMLProps } from 'react';
import NextLink from 'next/link';
import { getReferenceTarget, getTargetProps, Reference } from '../../../../helpers/reference';

type Props = Omit<HTMLProps<HTMLAnchorElement>, 'href' | 'ref'> & {
  reference: Reference;
};

const Reference: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  reference,
  children,
  ...anchorProps
}) => {
  if (reference === null) {
    return <>{children}</>;
  }

  return (
    <NextLink href={getReferenceTarget(reference)} {...anchorProps} {...getTargetProps(reference)}>
      {children}
    </NextLink>
  );
};

export default Reference;
