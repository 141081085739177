import React, { useEffect, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import Image from '../../../atoms/image'
import FullPageWidthWrapper from '../../../molecules/Layout/FullPageWidthWrapper'
import fetch from '@frontastic/bridge/js/fetch'

const SeeMoreBlogs = ({ blogArticle }) => {
    if (!blogArticle) {
        return null
    }

    let currentTags = blogArticle.new_tags ? blogArticle.new_tags : blogArticle.tags
    if (!Array.isArray(currentTags)) {
        currentTags = [currentTags]
    }

    currentTags = currentTags.join(',')

    if (!currentTags) {
        return null
    }

    const [blogArticles, setBlogArticles] = useState([])

    useEffect(() => {
        fetch(`/api/graphcms/bloglist/tag/${currentTags}/${blogArticle.slug}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.data) {
                    setBlogArticles(data.data.blogArticles)
                }
            })
    }, [currentTags])

    const blogTemplate = (blogArticle) => {
        const myString = blogArticle.article_text.text.replace(/\\n|\\r\\n|\\r|\\n\\n/g, '<br />')
        const blogTags = blogArticle.new_tags ? blogArticle.new_tags : blogArticle.tags
        return (
            <div className={'blog-category-item'}>
                <div className={'blog-category-item-image'}>
                    {blogArticle.image ? (
                        <Image
                            media={blogArticle.image.url}
                            alt={blogArticle.slug}
                            title={blogArticle.slug}
                            className={'blog-category-item-image-img'}
                            height={500}
                            width={750}
                            disableImageCropping
                        />

                    ) : (
                        <div
                            className={'blog-category-item-image-placeholder blog-category-item-image-img'}
                        />
                    )}
                </div>

                {blogTags && (
                    <div className={'blog-category-item-tags'}>
                        {blogArticle.new_tags && blogArticle.new_tags.length > 0 ? (
                            <>
                                {blogTags.map((tag) => (
                                    <span className={'blog-category-item-tags-text'}>{tag}</span>
                                ))}
                            </>
                        ) : (
                            <span className={'blog-category-item-tags-text'}>{blogArticle.tags}</span>
                        )}
                    </div>
                )}

                {blogArticle.headline && (
                    <h4 className={'blog-category-item-headline'}>
                        {blogArticle.headline}
                    </h4>
                )}

                {blogArticle.article_text.text && (
                    <div className={'blog-category-item-description'}>
                        {parse(myString)}
                    </div>
                )}

                <div className={'blog-category-item-link'}>
                    <FormattedMessage id={'blog.showDetail'} />
                </div>
            </div>
        )
    }

    return (
        <FullPageWidthWrapper>
            <div className={'o-wrapper '}>
                <div className={'see-more-blogs-wrapper'}>
                    <h2>
                        <FormattedMessage id={'blog.moreBlogHeadline'} />
                    </h2>

                    <div className={'blog-category-wrapper'}>
                        {blogArticles.map((blogArticle) => (
                            <div
                                className={'blog-category-wrapper-item'}
                                key={blogArticle.id}
                                onClick={() => {
                                    window.scroll({
                                        top: 0,
                                        behavior: 'smooth',
                                    })
                                }}
                            >
                                <a href={blogArticle.url} title={blogArticle.headline}>
                                    {blogTemplate(blogArticle)}
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </FullPageWidthWrapper>
    )
}

SeeMoreBlogs.propTypes = {
    blogArticle: PropTypes.object.isRequired,
}

export default injectIntl(SeeMoreBlogs)
