import React from 'react'
import PropTypes from 'prop-types'
import { FormattedDate, injectIntl } from 'react-intl'
import parse from 'html-react-parser'
import GraphImage from '../../../atoms/graphImage'

const BlogArticleElements = ({ blogArticle }) => {
    if (!blogArticle) {
        return null
    }

    let tags = blogArticle.new_tags ? blogArticle.new_tags : blogArticle.tags
    if (!Array.isArray(tags)) {
        tags = [tags]
    }

    return (
        <div className={'blog-element /markdown'}>
            {tags && (
                <div className={'blog-element-tag'}>
                    <span className={'blog-element-tag-text'}>
                        {blogArticle.new_tags && blogArticle.new_tags.length > 0 ? (
                            tags[0]
                        ) : (blogArticle.tags)}
                    </span>
                </div>
            )}

            {blogArticle.headline && (
                <h1>
                    {blogArticle.headline}
                </h1>
            )}

            <div className={'blog-element-created'}>
                {blogArticle.autor && (
                    <div className={'blog-element-created-author-name'}>
                        <span>{blogArticle.autor}</span>
                    </div>
                )}

                {blogArticle.autor && (
                    <div className={'blog-element-created-middle'}>
                        {' - '}
                    </div>
                )}

                {blogArticle.createdAt && (
                    <div className={'blog-element-created-date'}>
                        <span>
                            <FormattedDate value={blogArticle.createdAt} />
                        </span>
                    </div>
                )}
            </div>

            <div className={'blog-element-content'}>
                {blogArticle.image && (
                    <GraphImage
                        src={blogArticle.image.url}
                        alt={'image'}
                        className={'blog-element-content-preview-image'}
                        height={330}
                        width={1000}
                    />
                )}

                {blogArticle.article_text.html && (
                    <div className={'blog-element-content-description'}>
                        {parse(blogArticle.article_text.html)}
                    </div>
                )}
            </div>

            {tags && (
                <div className={'blog-element-tags'}>
                    <div className={'blog-element-tag'}>
                        {tags.map((tag) => (
                            <div className={'blog-element-tag-text'}>{tag}</div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

BlogArticleElements.propTypes = {
    blogArticle: PropTypes.object.isRequired,
}

export default injectIntl(BlogArticleElements)
