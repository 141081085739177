import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FormattedMessage, injectIntl } from 'react-intl';

import RangeFacet from './../Facets/RangeFacet';
import TermFacet from './../Facets/TermFacet';
import FacetService from './../FacetService';

import { ReactComponent as IconChevronUp } from '../../../../../../icons/tailwind-icons/icon-chevron-up.svg';
import { ReactComponent as IconChevronDown } from '../../../../../../icons/tailwind-icons/icon-cheveron-down.svg';

const FacetPopup = ({ intl, initialFacet, onChange }) => {
  const [facet, setFacet] = useState({ ...initialFacet });

  const locale = useSelector((globalState) => {
    return globalState.app && globalState.app.context && globalState.app.context.locale;
  });

  // @COFIXME[facets](FLBML-120): read facets from redux store
  // const facetsData =  useSelector((globalState) => {
  //     return globalState.facet && globalState.facet.facets && globalState.facet.facets.data
  // })
  const facetsData = [];

  const onOpenPopup = () => {
    setFacet({ ...initialFacet });
  };

  const getFacetName = () => {
    const c = facetsData.find((f) => {
      return f.attributeId === initialFacet.key;
    });

    if (c && c.label) {
      return c.label[locale];
    }

    return FacetService.getFacetName(initialFacet);
  };

  const getFacetLabel = () => {
    return FacetService.getFacetLabelValue(initialFacet);
  };

  const onApplyClick = (closeCallback) => {
    closeCallback();

    if (onChange) {
      onChange({ ...facet });
    }
  };

  const onClearClick = (closeCallback) => {
    FacetService.clearFacet(facet);

    closeCallback();

    if (onChange) {
      onChange({ ...facet });
    }
  };

  return (
    <Popup
      trigger={(open) => {
        return (
          <div
            className={classnames({
              'border rounded mr-2 flex h-8 w-32 cursor-pointer select-none items-center justify-between px-2': true,
              'bg-gray-300': open,
              'bg-white': !open,
              'border-gray-300 text-gray-600': !getFacetLabel(),
              'border-gray-700 text-gray-900': getFacetLabel(),
            })}
          >
            <span className="text-sm capitalize">
              {getFacetName()}
              {getFacetLabel() && <span className="ml-1 text-gray-600">({getFacetLabel()})</span>}
            </span>
            {open ? <IconChevronUp className="ml-2 inline-block" /> : <IconChevronDown className="ml-2 inline-block" />}
          </div>
        );
      }}
      arrow={false}
      position="bottom left"
      onOpen={onOpenPopup}
      contentStyle={{
        padding: '0px',
        border: 'none',
        width: '247px',
        marginTop: '5px',
        height: facet.type === 'term' ? '416px' : '238px',
        overflowY: 'hidden',
      }}
    >
      {(close) => {
        return (
          <div className="shadow relative z-10 flex flex-col pt-4">
            {facet.type === 'term' && (
              <div className="overflow-y-scroll px-4" style={{ height: '330px' }}>
                <TermFacet facet={facet} />
              </div>
            )}

            {facet.type === 'range' && (
              <div className="px-4" style={{ height: '153px' }}>
                <RangeFacet facet={facet} />
              </div>
            )}

            <div className="shadow flex w-full justify-around bg-white p-4">
              <button
                aria-label={intl.formatMessage({ id: 'filters.clear' })}
                className="font-normal text-gray-900"
                onClick={() => {
                  onClearClick(close);
                }}
              >
                <FormattedMessage id="filters.clear" />
              </button>

              <button
                aria-label={intl.formatMessage({ id: 'filters.apply' })}
                className="btn btn-primary"
                onClick={() => {
                  onApplyClick(close);
                }}
              >
                <FormattedMessage id="filters.apply" />
              </button>
            </div>
          </div>
        );
      }}
    </Popup>
  );
};

FacetPopup.propTypes = {
  intl: PropTypes.object.isRequired,
  initialFacet: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

export default injectIntl(FacetPopup);
