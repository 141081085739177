import React from 'react'
import PropTypes from 'prop-types'

/**
 * ImageWrapper component
 * use this component to wrap an image and make it responsive
 * by setting the width and height of the image equal to the width and height of the wrapper
 *
 * @param {Object} props
 * @param {Object} props.child - The child element to be wrapped (e.g. <img src="image.jpg" />)
 * @param {Number} props.width - The width of the image (e.g. 100)
 * @param {Number} props.height - The height of the image (e.g. 100)
 * @param {number} props.fixedWidth - The fixed width, force the wrapper's width (e.g. '500px', '50%')
 * @param {String} props.className - The class name of the wrapper (e.g. "my-class")
 * @param {Object} props.innerRef - The ref of the wrapper (e.g. React.createRef())
 * @param {Boolean} props.disableImageCropping - Disable the image cropping (e.g. true)
 *
 * @returns {JSX.Element}
 *
 */

const ImageWrapper = ({
    child,
    width,
    height,
    fixedWidth,
    innerRef,
    className = '',
    disableImageCropping = false,
}) => {
    // get the styles for the wrapper
    const getStyles = () => {
        let styles

        if (disableImageCropping === true) {
            return {
                paddingBottom: height,
            }
        }

        if (height && width) {
            return {
                paddingBottom: `calc(${height} / ${width} * 100%)`,
            }
        }

        return styles
    }

    return (
        <div
            ref={innerRef}
            className={`relative z-0 flex w-full ${className}`}
            style={{
                maxWidth: fixedWidth || '100%',
            }}
        >
            <div className={'relative w-full h-full'} style={{ zIndex: 1 }}>
                <div className={'w-full'} style={getStyles()} />
                {child}
            </div>
        </div>
    )
}

ImageWrapper.propTypes = {
    child: PropTypes.node.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    fixedWidth: PropTypes.string,
    className: PropTypes.string,
    innerRef: PropTypes.object,
    disableImageCropping: PropTypes.bool,
}

export default ImageWrapper
