import React from 'react'
import PropTypes from 'prop-types'

export default function FullPageWidthWrapper({
    children, customClasses = '', style, withWrapper = false,
}) {
    return (
      <div
        className={`width--fullsize ${customClasses}`}
        style={style}
      >
          {withWrapper ? (
            <div className={'o-wrapper'}>
                <div className={'o-grid o-region'}>
                    <div className={'o-cell o-cell--12'}>
                        {children}
                    </div>
                </div>
            </div>
          ) : children}
      </div>
    )
}
FullPageWidthWrapper.propTypes = {
    children: PropTypes.any,
    style: PropTypes.any,
    withWrapper: PropTypes.bool,
    customClasses: PropTypes.string,
}
