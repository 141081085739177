import MediaImage from '@frontastic/catwalk/src/js/mediaImage'
import debounce from '@frontastic/common/src/js/helper/debounce'
import GraphImage from '@graphcms/react-image'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import ImageWrapper from './ImageWrapper'

/**
 * This is a custom image component that is used to render images from GraphCMS and Meleven.
 * It is a copy of the original Image component from Frontastic, with the following changes:
 * - Added support for GraphCMS images
 * - Added support for Media images - Images are uploaded in the Studio
 *
 * @param {string} height - Height of the image
 * @param {string} width - Width of the image
 * @param {any} media - Media object or URL string
 * @param {string} alt - Alt text
 * @param {string} title - Title text
 * @param {boolean} lazyLoad - Lazy load the image
 * @param {boolean} triggerImageDimensionUpdate - Trigger image dimension update
 * @param {boolean} disableImageCropping - Disable image cropping
 * @param {boolean} disableBlurryPlaceholder - Disable blurry placeholder
 * */
const Image = ({
    height,
    width,
    media,
    alt,
    title,
    fixedWidth, // This is a priority parent width if you don't want follow width of parent element
    lazyLoad = false,
    triggerImageDimensionUpdate = true,
    disableImageCropping = false,
    disableBlurryPlaceholder = false,
}) => {
    // If no media is provided, return an empty div
    if (!media) {
        return (
            <div className={'bg-blacksport-10'} style={{ width, height }} />
        )
    }

    const mediaTypes = {
        GRAPH: 'GRAPH',
        MEDIA: 'MEDIA',
        URL: 'STRING',
    }

    // Get the media type
    const getMediaType = () => {
        if (typeof media === 'object') {
            return mediaTypes.MEDIA
        }

        if (media.includes('media.graphassets.com')) {
            return mediaTypes.GRAPH
        }

        if (typeof media === 'string') {
            return mediaTypes.URL
        }

        throw new Error('Unknown media type')
    }

    // wrapperWidth and wrapperHeight are the dimensions of the image wrapper
    // if the image is wrapped, the wrapper dimensions are used to generate the thumbnail url
    const [wrapperWidth, setWrapperWidth] = useState(width)
    const [wrapperHeight, setWrapperHeight] = useState(height)

    // refImageWrapper is the reference to the image wrapper
    const imageWrapperRef = useRef(null)

    const mediaType = getMediaType()
    const hiDpiMultiplier = 1 // TODO: Add support for HiDPI images
    const altProp = alt || title || ''
    const titleProp = title || alt || ''

    // if the image is not wrapped, the image default dimensions are used to generate the thumbnail url
    const imageProp = {
        width: (fixedWidth || wrapperWidth || width || 0) * hiDpiMultiplier,
        height: (wrapperHeight || height || 0) * hiDpiMultiplier,
    }

    // update wrapper dimensions on resize event
    const updateWrapperDimensions = () => {
        if (imageWrapperRef.current) {
            setWrapperWidth(imageWrapperRef.current.offsetWidth)
            setWrapperHeight(imageWrapperRef.current.offsetHeight)
        }
    }

    useEffect(() => {
        updateWrapperDimensions()
    }, [triggerImageDimensionUpdate])

    useEffect(() => {
        updateWrapperDimensions()

        window.addEventListener(
            'resize',
            debounce(() => updateWrapperDimensions(), 250),
            { passive: true },
        )

        return window.removeEventListener(
            'resize',
            debounce(() => updateWrapperDimensions(), 250),
        )
    }, [])

    // handle GraphCMS image logic
    const GraphCMSImage = () => {
        const graphCMSMediaId = media.replace('https://media.graphassets.com/', '')
        const fitProp = disableImageCropping ? 'clip' : 'crop'
        const transformsProp = 'output=quality:98,strip:true/sharpen=amount:1'

        return (
            <GraphImage
                image={{
                    ...imageProp,
                    handle: graphCMSMediaId,
                }}
                maxWidth={imageProp.width}
                fit={fitProp}
                transforms={[transformsProp]}
                blurryPlaceholder={!disableBlurryPlaceholder}
                alt={altProp}
                title={titleProp}
                className={'graphcms-image--img'}
                outerWrapperClassName={classnames('graphcms-image', {
                    'disable-image-cropping': disableImageCropping,
                })}
            />
        )
    }

    // handle Media image logic
    const CustomMediaImage = () => (
        <MediaImage
            media={media}
            title={titleProp}
            draggable={false}
            width={imageProp.width}
            height={imageProp.height}
            style={{
                maxWidth: imageProp.width,
            }}
            className={classnames('main-image', {
                'disable-image-cropping': disableImageCropping,
            })}
        />
    )

    // handle URL string image logic
    const UrlStringImage = () => (
        <img
            style={{ maxWidth: imageProp.width }}
            alt={altProp}
            title={titleProp}
            loading={lazyLoad ? 'lazy' : 'eager'}
            decoding={'async'}
            src={media}
            draggable={false}
            className={classnames('main-image', {
                'disable-image-cropping': disableImageCropping,
            })}
        />
    )

    // handle image wrapper logic
    const Wrapper = (child) => (
        <ImageWrapper
            innerRef={imageWrapperRef}
            width={fixedWidth || imageProp.width || media.media?.width}
            height={imageProp.height || media.media?.height}
            child={child}
            disableImageCropping={disableImageCropping}
        />
    )

    switch (mediaType) {
        case mediaTypes.GRAPH:
            return (<GraphCMSImage />)
        case mediaTypes.MEDIA:
            return (Wrapper(<CustomMediaImage />))
        case mediaTypes.URL:
            return (Wrapper(<UrlStringImage />))
        default:
            throw new Error('Unknown media type')
    }
}

Image.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    media: PropTypes.any,
    alt: PropTypes.string,
    title: PropTypes.string,
    lazyLoad: PropTypes.string,
    fixedWidth: PropTypes.any,
    triggerImageDimensionUpdate: PropTypes.bool,
    disableImageCropping: PropTypes.bool,
    disableBlurryPlaceholder: PropTypes.bool,
}

export default Image
