import React from 'react';
import PropTypes from 'prop-types';

import FacetService from './../FacetService';
import { ReactComponent as IconCheck } from '../../../../../../icons/check.svg';

const TermFacet = ({ facet, onChange }) => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const onTermClicked = (term) => {
    term.selected = !term.selected;

    facet.selected = facet.terms.some((term) => {
      return term.selected === true;
    });

    if (onChange) {
      onChange(facet);
    }

    forceUpdate();
  };

  return (
    <div className="flex flex-col">
      {facet.terms.map((term, index) => {
        return (
          <div
            key={index}
            onClick={() => {
              onTermClicked(term);
            }}
            className="border rounded mb-2 box-border flex h-10 w-full cursor-pointer select-none items-center border-gray-300 px-3 py-2"
          >
            {FacetService.isColorFacet(facet) && (
              <span
                style={FacetService.getColorStyle(term.value)}
                className="border mr-2 h-4 w-4 rounded-full border-gray-300"
              />
            )}

            <span className="text-sm leading-normal text-gray-900">{term.name}</span>

            {term.selected && <IconCheck className="ml-auto inline-block fill-current text-indigo-500" />}
          </div>
        );
      })}
    </div>
  );
};

TermFacet.propTypes = {
  facet: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

export default TermFacet;
