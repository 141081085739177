import React from 'react'
import GraphImage from '@graphcms/react-image'
import classnames from 'classnames'

type Props = {
    src: string
    width?: number
    height?: number
    alt?: string
    disableImageCropping?: boolean
}

const CustomGraphImage = ({ src, width, height, alt, disableImageCropping = false }: Props) => {
    if (!src) {
        return null
    }
    const graphCMSMediaId = src.replace('https://media.graphassets.com/', '')
    const fit = disableImageCropping ? 'clip' : 'crop'
    const transformsProp = 'output=quality:98,strip:true/sharpen=amount:1'

    return (
        <GraphImage
            image={{
                width,
                height,
                handle: graphCMSMediaId,
            }}
            fit={fit}
            transforms={[transformsProp]}
            blurryPlaceholder
            alt={alt}
            className={'graphcms-image--img'}
            outerWrapperClassName={classnames('graphcms-image', {
                'disable-image-cropping': disableImageCropping,
            })}
        />
    )
}

export default CustomGraphImage
