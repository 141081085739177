import { fetchApiHub, rawFetchApiHub } from '../../../frontastic';

interface RequestOptions {
  method?: 'GET' | 'POST';
  headers?: Record<string, string>;
  body?: string;
}

const fetch = (url: string, options: RequestOptions): Promise<Response> => {
  return rawFetchApiHub(
    '/action/legacy-data/api-proxy',
    {
      method: 'POST',
    },
    {
      route: {
        path: url,
      },
      method: options.method || 'GET',
      headers: options.headers || {},
      body: options.body || null,
    },
  );
};

export default fetch;
