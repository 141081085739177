import React from 'react';
import { connect } from 'react-redux';

import withTranslatedTasticData from '../component/withTranslatedTasticData';
import emptyEntity from '@frontastic/catwalk/js/helper/emptyEntity';

/**
 * An object of possible selectors for tastify()
 *
 * The key of the selector is being used as the prop name and also as the `configuration.connect`.
 *
 * This means, if `configuration.connect.context` is set, the function below with the key `context` is being executed,
 * and the result will be passed in the `context` prop.
 */
const availableSelectors = {
  context: (state) => {
    return state.app.context;
  },
  cart: (state) => {
    return state.cart?.cart || emptyEntity;
  },
  order: (state) => {
    if (state.app.route.has('order')) {
      return state.cart.orders[state.app.route.get('order')] || emptyEntity;
    } else {
      return state.cart.lastOrder || emptyEntity;
    }
  },
  wishlist: (state) => {
    return state.wishlist?.wishlist || emptyEntity;
  },
  notifications: (state) => {
    return state.user?.notifications || emptyEntity;
  },
  deviceType: (state) => {
    return state.renderContext?.deviceType || emptyEntity;
  },
  isServerSideRendering: (state) => {
    return state.renderContext?.serverSideRendering || emptyEntity;
  },
  route: (state) => {
    return state.app?.route;
  },
};

/**
 * Connects the tastic to the redux store, if necessary
 *
 * It uses the above availableSelectors object and checks which of those selectors are enabled in the configuration.
 * If no selector is active, the component will not be connected to redux at all.
 */
const connectedTasticForConfiguration = (Tastic, configuration) => {
  const selectors = {};

  if (configuration.translate) {
    Tastic = withTranslatedTasticData(Tastic);
  }

  Object.keys(availableSelectors).forEach((selectorName) => {
    if (configuration.connect[selectorName]) {
      selectors[selectorName] = availableSelectors[selectorName];
    }
  });

  if (Object.keys(selectors).length === 0) {
    // Apparently, no selector should be used, thus we do not need to connect the tastic to redux at all.
    return Tastic;
  }

  return connect((state) => {
    const props = {};

    Object.keys(selectors).forEach((selectorName) => {
      if (configuration.connect[selectorName]) {
        props[selectorName] = selectors[selectorName](state);
      }
    });

    return props;
  })(Tastic);
};

/**
 * Removes some props from the tastic, for performance optimizations.
 */
const filterPropsForConfiguration = (configuration, originalProps) => {
  const props = {
    ...originalProps,
  };

  if (!props.data?.stream && props.rawData?.stream?.__master) {
    if (props.rawData.stream.__master.frontasticBridgeDataSourceContent !== undefined) {
      props.data.stream = props.rawData.stream.__master.frontasticBridgeDataSourceContent;
    } else {
      props.data.stream = props.rawData.stream.__master;
    }
  }

  if (!configuration.connect.rawData) {
    delete props.rawData;
  }
  if (!configuration.connect.cart) {
    delete props.cart;
  }
  if (!configuration.connect.order) {
    delete props.order;
  }
  if (!configuration.connect.node) {
    delete props.node;
  }
  if (!configuration.connect.page) {
    delete props.page;
  }

  // The withTranslatedTasticData function requires the tastic schema, loaded
  // from the tastic prop. Therefore it must be made available as a prop.
  if (!configuration.connect.tastic && !configuration.translate) {
    delete props.tastic;
  }

  return props;
};

/**
 * HOC for tastics. Makes sure only required stuff is passed, which can be used as a performance improvement.
 *
 * Defaults to only passing the data prop.
 * In combination with React.PureComponent or React.memo this might reduce re-renders.
 *
 * @experimental This is a work-in-progress and might change in the future.
 * @param {Object} configuration.connect
 * @param {boolean} configuration.connect.rawData - Whether to pass "rawData". Should not be needed anymore.
 * @param {boolean} configuration.connect.node - Whether to pass information about the current node.
 * @param {boolean} configuration.connect.cart - Whether to pass information about the current cart.
 * @param {boolean} configuration.connect.order - Whether to pass information about the last or currently selected order
 * @param {boolean} configuration.connect.page - Whether to pass information about the current page.
 * @param {boolean} configuration.connect.tastic - Whether to pass the schema of the current tastic. Rarely needed.
 * @param {boolean} configuration.connect.wishlist - Whether to pass information about the current wishlist.
 * @param {boolean} configuration.connect.notifications - Whether to pass information about the current notifications.
 * @param {boolean} configuration.connect.context - Whether to pass the frontastic context object.
 * @param {boolean} configuration.connect.deviceType - Whether to pass the deviceType
 * @param {boolean} configuration.connect.isServerSideRendering - Whether we should pass a flag `isServerSideRendering`
 * @param {boolean} configuration.connect.route - Whether to pass information about the current route
 * @param {boolean} configuration.translate - Automatically translate tastic fields from backstage
 */
const tastify = (configuration = {}) => {
  return (WrappedComponent) => {
    if (!configuration.connect) {
      configuration.connect = {};
    }

    class TastifiedTastic extends React.Component {
      render() {
        const props = filterPropsForConfiguration(configuration, this.props);

        return <WrappedComponent {...props} />;
      }
    }

    TastifiedTastic.displayName = `TastifiedTastic(${getDisplayName(WrappedComponent)})`;

    return connectedTasticForConfiguration(TastifiedTastic, configuration);
  };
};

const getDisplayName = (WrappedComponent) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'UnknownTastic';
};

export default tastify;
