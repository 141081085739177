export default (value, currentLocale, defaultLocale) => {
  if (!value || typeof value !== 'object') {
    return {
      text: value,
      locale: currentLocale,
    };
  }

  if (value[currentLocale]) {
    return {
      text: value[currentLocale],
      locale: currentLocale,
    };
  }

  if (value[defaultLocale]) {
    return {
      text: value[defaultLocale],
      locale: defaultLocale,
    };
  }

  if (!Object.keys(value).length) {
    return { text: '', locale: null, translated: false };
  }

  let firstAvailableLocale = Object.keys(value)[0];
  return {
    text: value[firstAvailableLocale] || '',
    locale: firstAvailableLocale,
    translated: false,
  };
};
